/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_content_delivery_bucket": "upwork-madison-app2-20210804100716-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://dfxki8u3g27zf.cloudfront.net"
};


export default awsmobile;
